import { Dispatch, SetStateAction, useEffect, useState } from 'react'

type PersistedState<T> = [T, Dispatch<SetStateAction<T>>]

function usePersistedState<T>(
  defaultValue: T | null,
  key: string,
  persistType: 'session' | 'local' = 'session'
): PersistedState<T | null> {
  const windowStorage = persistType === 'local' ? window.localStorage : window.sessionStorage
  const [value, setValue] = useState<T | null>(() => {
    const persistedValue = windowStorage.getItem(key)

    return persistedValue ? (JSON.parse(persistedValue) as T) : defaultValue
  })
  useEffect(() => {
    if (window) {
      windowStorage.setItem(key, JSON.stringify(value))
    }
  }, [key, value])

  return [value, setValue]
}

export { usePersistedState }
