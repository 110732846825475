import { Box } from '@mui/material'
import { ContentContainer } from './styles'
import Header from '../Header'
import { useEffect, useState } from 'react'
import MenuDrawer from '../Menu'
import { useIsMobile } from '@/hooks/useIsMobile'
import { useUserContext } from '@/context/UserContext'
import LoadingScreen from '../LoadingScreen'

interface Props {
  children: JSX.Element
}

export default function Layout({ children }: Props) {
  const isSmall = useIsMobile()
  const [open, setOpen] = useState<boolean>(!isSmall)
  const { user, isLoadingScreenViewed } = useUserContext()

  useEffect(() => {
    setOpen(!isSmall)
  }, [isSmall])

  return (
    <Box sx={{ display: 'flex' }}>
      {user?.terms_accepted && (
        <>
          <Header open={open} setOpenMenu={setOpen} />
          <MenuDrawer open={open} />
        </>
      )}

      <ContentContainer open={open}>{children}</ContentContainer>
      {!isLoadingScreenViewed && <LoadingScreen />}
    </Box>
  )
}
