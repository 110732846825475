export enum Permissions {
  TaxDocumentUpload = 'upload:tax_document',
  ReadDocumentDispatch = 'read:follow-dispatch',
  ReadAdvanceBordero = 'read:advance-bordero',
  WriteAdvanceBordero = 'write:advance-bordero',
  ReadBordero = 'read:bordero',
  ReadInstallments = 'read:installments',
  WriteInstallments = 'write:installments',
  AdminReadInstallments = 'admin:read:installments',
  AdminVendorsUpload = 'admin:upload:vendor',
  AdminReadVendor = 'admin:read:vendor',
  AdminWriteVendor = 'admin:write:vendor',
  AdminReadTaxes = 'admin:read:interest',
  AdminWriteTaxes = 'admin:write:interest',
  AdminReadBordero = 'admin:read:bordero'
}
