import { useAuthContext } from '@/context/AuthContext'
import { Drawer, List, ListSubheader } from '@mui/material'
import DrawerFooterComponent from '../DrawerFooter'
import { MenuAccordion } from './accordion'
import MenuItem from './menuItem'
import menuItems from './menuItems'
import { DrawerHeader, drawerWidth } from './styles'
import { useUserContext } from '@/context/UserContext'

interface MenuDrawerProps {
  open: boolean
}

const filterPermissions = (userPermissions: string[], permissions: string[]): boolean => {
  return userPermissions.some((perm: string) => permissions.includes(perm)) || !permissions
}

export default function MenuDrawer({ open }: MenuDrawerProps) {
  const { permissions } = useAuthContext()
  const { logo } = useUserContext()

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <img alt="LOGO" src={logo} height="64px" />
      </DrawerHeader>

      {menuItems.map((menuHeader) => {
        return (
          <List
            subheader={
              <ListSubheader sx={{ color: 'grey.600' }}>{menuHeader.subheader}</ListSubheader>
            }
          >
            {menuHeader.menu.map((menu) => {
              return menu.submenu.length ? (
                <MenuAccordion menu={menu} />
              ) : filterPermissions(permissions, menu.permissions!) ? (
                <MenuItem
                  key={menu.path}
                  text={menu.text}
                  icon={menu.icon}
                  path={menu.path!}
                  match={menu.match!}
                />
              ) : (
                <></>
              )
            })}
          </List>
        )
      })}

      <DrawerFooterComponent />
    </Drawer>
  )
}
