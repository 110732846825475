import { styled } from '@mui/material'
import LoaderIcon from './LoaderIcon'

export const StyledLoader = styled('div')`
  display: block;
`

export const StyledLoaderIcon = styled(LoaderIcon)`
  display: block;
  margin: 0 auto;
`
