import { useUserContext } from '@/context/UserContext'
import { useIsMobile } from '@/hooks/useIsMobile'
import { Box, Typography } from '@mui/material'
import { useSpring, animated } from '@react-spring/web'
import { useEffect, useState } from 'react'
import { Image } from './style'

const LoadingScreen = () => {
  const isMobile = useIsMobile()
  const { theme, setLoadingScreenViewed, user } = useUserContext()
  const [transition, setTransition] = useState<{ fromY: number; toY: number }>({
    fromY: window.innerHeight,
    toY: 0
  })
  const [showLoading, setShowLoading] = useState(true)
  const springs = useSpring({
    from: { y: transition.fromY },
    to: { y: transition.toY },
    config: { duration: 500 }
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setTransition({ fromY: 0, toY: window.innerHeight })
      setTimeout(() => {
        setShowLoading(false)
        setLoadingScreenViewed()
      }, 600)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  return showLoading ? (
    <animated.div
      style={{
        backgroundColor: theme.palette.primary.main,
        position: 'fixed',
        zIndex: 9999,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...springs
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '48px' }}>
        <Image src={user?.funding_bank.secondary_logo_url} isMobile={isMobile} />
        <Typography variant="h1" color={theme.palette.primary.contrastText}>
          cessão de crédito
        </Typography>
      </Box>
    </animated.div>
  ) : (
    <></>
  )
}

export default LoadingScreen
