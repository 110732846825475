import { ThemeProvider, createTheme } from '@mui/material/styles'
import ReactDOM from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { getTheme } from '@libercapital/react-theme'

import '@/datadog'

import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  PRIMARY_COLOR,
  SECONDARY_COLOR
} from './env'
import { BrowserRouter } from 'react-router-dom'
import '../public/assets/css/main.css'
import '../public/assets/css/normalize.css'
import { AuthProvider } from './context/AuthContext'
import App from './App'

const theme = createTheme(getTheme(), {
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    secondary: {
      main: SECONDARY_COLOR
    }
  }
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    useRefreshTokens
    cacheLocation="localstorage"
    authorizationParams={{
      audience: AUTH0_AUDIENCE,
      redirect_uri: window.location.origin
    }}
  >
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  </Auth0Provider>
)
