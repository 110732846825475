import { StyledLoader, StyledLoaderIcon } from './styles'

const Loader = () => {
  return (
    <StyledLoader>
      <StyledLoaderIcon />
    </StyledLoader>
  )
}

export default Loader
