import { clearUserSession } from '@/helpers/storage'
import { usePersistedState } from '@/hooks/usePersistedState'
import { useAuth0 } from '@auth0/auth0-react'
import { JwtPayload, jwtDecode } from 'jwt-decode'
import { ReactNode, createContext, useContext, useEffect } from 'react'

export interface IAuthenticationContext {
  permissions: string[]
  isAuthenticated: boolean
  isLoading: boolean
}

export const AuthContext = createContext<IAuthenticationContext>({
  isLoading: true,
  isAuthenticated: false,
  permissions: []
})

type Props = {
  children: ReactNode
}

export const AuthProvider = ({ children }: Props) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0()
  const [permissions, setPermissions] = usePersistedState<string[]>([], 'permissions')

  const getAuthPermissions = async () => {
    try {
      const access_token = await getAccessTokenSilently()
      const { permissions: jwtPermissions } = jwtDecode<JwtPayload & { permissions: string[] }>(
        access_token
      )
      setPermissions([...jwtPermissions, 'disabled'])
    } catch (e) {
      login()
      clearUserSession()
      console.error(e)
      throw e
    }
  }

  const login = async () => {
    await loginWithRedirect({ appState: { returnTo: window.location.pathname } })
  }

  useEffect(() => {
    if (!isLoading && !isAuthenticated) login()
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    if (isAuthenticated) getAuthPermissions()
  }, [isAuthenticated])

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, permissions: permissions || [] }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  return useContext(AuthContext)
}
