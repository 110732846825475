import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Theme } from '@mui/material'
import FullSizeLoader from '@/components/FullSizeLoader'
import { useHttpRequest } from '@/hooks/useHttpRequest'
import { usePersistedState } from '@/hooks/usePersistedState'
import { getTheme } from '@libercapital/react-theme'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { User } from '@/types/User'
import { useNavigate } from 'react-router-dom'

export interface IUserContext {
  user?: User
  isLoading: boolean
  theme: Theme
  logo: string
  refetchUser: () => void
  isLoadingScreenViewed: boolean
  setLoadingScreenViewed: () => void
}

export const UserContext = createContext<IUserContext>({
  isLoading: true,
  theme: {} as Theme,
  logo: '',
  refetchUser: () => null,
  isLoadingScreenViewed: false,
  setLoadingScreenViewed: () => null
})

type Props = {
  children: ReactNode
}

export const UserProvider = ({ children }: Props) => {
  const [storedUser, setStoredUser] = usePersistedState<User>(null, 'user', 'local')
  const [isLoadingScreenViewed, setLoadingScreenViewed] = usePersistedState<boolean>(
    false,
    'userLoadingScreen',
    'session'
  )
  const { fetch } = useHttpRequest()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const getUserData = async () => {
    setIsLoading(true)
    try {
      const { data } = await fetch<User>({ method: 'GET', path: '/users/me' })
      setStoredUser(data)
      checkUserTerms(data)
    } catch (err) {
      throw new Error('Cannot get user data')
    } finally {
      setIsLoading(false)
    }
  }

  const checkUserTerms = (user: User) => {
    if (user && !user.terms_accepted) {
      navigate('/finish-register')
    }
  }

  useEffect(() => {
    if (!storedUser) {
      getUserData()
    } else {
      checkUserTerms(storedUser)
    }
  }, [])

  const logo = useMemo(() => {
    return storedUser
      ? storedUser?.funding_bank.primary_logo_url
      : `${window.location.origin}/assets/img/logo.svg`
  }, [storedUser])

  const getCurrTheme = useCallback(() => {
    if (storedUser) {
      const theme = createTheme(getTheme(), {
        palette: {
          primary: {
            main: storedUser.funding_bank.primary_color
          },
          secondary: {
            main: storedUser.funding_bank.secondary_color
          }
        }
      })
      return theme
    }
    return getTheme()
  }, [storedUser])

  return (
    <UserContext.Provider
      value={{
        isLoading,
        theme: getCurrTheme(),
        user: storedUser || undefined,
        logo,
        refetchUser: getUserData,
        isLoadingScreenViewed: !!isLoadingScreenViewed,
        setLoadingScreenViewed: () => setLoadingScreenViewed(true)
      }}
    >
      <ThemeProvider theme={getCurrTheme()}>
        {isLoading ? <FullSizeLoader /> : children}
      </ThemeProvider>
    </UserContext.Provider>
  )
}

export const useUserContext = () => {
  return useContext(UserContext)
}
